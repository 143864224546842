<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">Menu Builder</li>
        </ol>
        <h1 class="page-header mb-0">Menu Builder</h1>
      </div>
      <div class="ms-auto">
        <!-- <a @click="$bvModal.show('modalDialog')" class="btn btn-success btn-rounded px-4 rounded-pill"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a> -->
        <a
          class="btn btn-success btn-rounded px-4 rounded-pill"
          @click="updateMenu"
        >
          <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Menu
        </a>
      </div>
    </div>

    <div class="card border-0 p-4">
       <div class="table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th class="pt-0 pb-2"></th>
                  <th class="pt-0 pb-2">Parent</th>
                  <th class="pt-0 pb-2">Display Name</th>
                  <th class="pt-0 pb-2">Category</th>
                  <th class="pt-0 pb-2">IsPublic</th>
                  <th class="pt-0 pb-2">Actions</th>
                </tr>
              </thead>
              <draggable v-model="menus" tag="tbody" @change="updateData">
                <tr v-for="(menu, index) in menus" :key="menu.id">
                  <td class="w-10px align-middle">
                    <div class="form-check">
                      <input
                        id="product1"
                        type="checkbox"
                        class="form-check-input"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td class="align-middle">
                     <b-form-select
                        v-model="menu.parent_id" class="form-select"
                          :options="categories"
                          @change="update(menu,'parent_id')"
                        ></b-form-select>
                  </td>
                  <td class="align-middle">
                    <b>{{ menu.label }}</b>
                  </td>
                  <td class="align-middle">{{ menu.category?.name }}</td>
                  <td class="align-middle">
                    <b-form-checkbox
                      v-model="menu.is_public"
                      switch
                      :value="1"
                      type="checkbox"
                      class="custom-control-input"
                      @change="update(menu, 'is_public')"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitch1"
                    ></label>
                  </td>
                  <td>
                    <div class="fa-1x">
                      <!-- <a href="" @click.prevent="editAuthor(author.id)">
                        <i class="fas fa-pencil-alt fa-fw"></i>
                      </a> -->
                      <a
                        class="text-danger ps-3"
                        href=""
                        @click.prevent="deleteMenu(menu, index)"
                      >
                        <i class="fas fa-trash fa-fw"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
          </div>

    </div>

    <b-modal id="addModal" hide-footer title="Add New Menu">
<form>
        <div class="mb-3">
          <label for="category" class="form-label">Category Name</label>
          <b-form-select
                        v-model="newCategoryId" class="form-select"
                          :options="nonReservedCategories"
                        ></b-form-select>
        </div>
<div class="mb-3">
        <label for="category" class="form-label">Display Name</label>
        <input v-model="label" class="form-control"/>
        </div>
        <button
          type="submit"
          class="btn btn-success float-end"
          @click.prevent="addMenu"
        >
          Submit
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from "../common/http"

import draggable from "vuedraggable"

export default {
  name: "Menus",
  components: {
    draggable,
  },
 data () {
    return {
      label:'',
      menus:[],
      categories:[],
      newCategoryId:0,
      nonReservedCategories:[],
      nestableItems: [
        {
          key: 0,
          class: 'purple-text-color',
          text: 'Andy'
        }, {
          key: 1,
          class: 'blue-text-color',
          text: 'Harry',
          nested: [{
            key: 2,
            text: 'David'
          }]
        }, {
          key: 3,
          class: 'red-text-color',
          text: 'Lisa'
        }, {
          key: 4,
          text: 'I can not be nested'
        }
      ]
    }
  },
watch:{
  newCategoryId(newValue, oldValue){
    this.label = this.nonReservedCategories[newValue]
    console.log(oldValue)
  }
},
  created(){
    this.getData()
  },
  methods: {
    addMenu(){
      HTTP.post(`admin/menus`, {category_id:this.newCategoryId, label:this.label})
        .then(() => {
          this.getData()
          this.$bvModal.close('addModal')
            this.$notify({
            group: "bottom-left",
            title: "success",
            type: 'success'
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    updateMenu(){
      this.$bvModal.show('addModal')
      this.nonReservedCategories = this.getCategories()
    },
    updateData(){
      let data = this.menus.map(item => item.id)
      HTTP.post(`admin/update-menu-order`, {ordering:data})
        .then(() => {
            this.$notify({
            group: "bottom-left",
            title: "success",
            type: 'success'
          })
        })
        .catch(err => {
          console.log(err)
        })
      },
    update(menu, attribute){
      HTTP.put(`/admin/menus/${menu.id}`, {[attribute]:menu[attribute]}).then(res=>{
        console.log(res.data.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    deleteMenu(menu, index){
      HTTP.delete(`/admin/menus/${menu.id}`).then(res=>{
        console.log(res.data.data)
        this.$delete(this.menus, index)
        this.$notify({
            group: "bottom-left",
            title: "success",
            type: 'success'
          })
      }).catch(err=>{
        console.log(err)
        this.$notify({
            group: "bottom-left",
            title: "failed",
            type: 'error'
          })
      })
    },
    getData(){
      HTTP.get('/admin/menus').then(res=>{
        this.menus = res.data.data
        this.categories = res.data.data.map(item => {
          return {
            text:item.label,
            value:item.id
          }
        })  
      }).catch(()=>{})
    },
    getCategories(){
      HTTP.get("/admin/non-reserved-menu")
          .then(response => {
            this.nonReservedCategories = response.data.data
          })
          .catch(e => {
            this.errors.push(e)
          })
    },
    beforeMove ({ dragItem, pathTo }) {
      // Item 4 can not be nested more than one level
      if (dragItem.key === 4) {
        return pathTo.length === 1
      }
      // All other items can be
      return true
    }
  }
}
</script>

<style scoped>
.form-select {
    width: 50% !important;
}
.blue-text-color {
  color: #415ad4;
}
.purple-text-color {
  color: #b43ceb;
}
.red-text-color {
  color: #e13a3a;
}
.nestable {
  position: relative;
}
.nestable-rtl {
  direction: rtl;
}
.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}
.nestable-rtl .nestable-list {
  padding: 0 40px 0 0;
}
.nestable > .nestable-list {
  padding: 0;
}
.nestable-item,
.nestable-item-copy {
  margin: 10px 0 0;
}
.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}
.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  margin-top: 10px;
}
.nestable-item {
  position: relative;
}
.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}
.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}
.nestable-item.is-dragging:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 127, 233, 0.274);
  border: 1px dashed rgb(73, 100, 241);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}
.nestable-rtl .nestable-drag-layer {
  left: auto;
  right: 0;
}
.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(106, 127, 233, 0.274);
}
.nestable-rtl .nestable-drag-layer > .nestable-list {
  padding: 0;
}
.nestable [draggable="true"] {
  cursor: move;
}
.nestable-handle {
  display: inline;
}
.fas{
  margin-right: 5px;
}
/* #switch input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

#switch label {
	cursor: pointer;
	text-indent: -9999px;
	width: 45px;
	height: 20px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

#switch label:after {
	content: '';
	position: absolute;
	top: 5px;
	left: 5px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

#switch input:checked + label {
	background: #bada55;
}

#switch input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

#switch label:active:after {
	width: 30px;
} */

</style>

