<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
          <li class="breadcrumb-item active">HomePage Management</li>
        </ol>
        <h1 class="page-header mb-0">HomePage Management</h1>
      </div>
      <div class="ms-auto">
        <a
          class="btn btn-success btn-rounded px-4 rounded-pill"
          @click="editItem"
        >
          <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add
          Category
        </a>
      </div>
    </div>

    <div class="card border-0">
      <ul class="nav nav-tabs nav-tabs-v2 px-3">
        <li v-for="tab, i in types" :key="i" class="nav-item me-2">
          <a
:href="`#${tab}`" class="nav-link px-2" 
          :class="{active: currentTab === i}" 
        data-bs-toggle="tab" 
        @click="currentTab = i"
            >{{tab}}</a
          >
        </li>
      </ul>
      <div class="tab-content p-3">
        <div
v-for="(tab, index) in types" :id="tab" :key="`tab-${index}`" 
        class="tab-pane fade show" 
        :class="{active: currentTab === index}" 
        @click="currentTab = index">
          <!-- BEGIN table -->
          <div class="table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th class="pt-0 pb-2">SN</th>
                  <th class="pt-0 pb-2">Category Name</th>
                  <th class="pt-0 pb-2">Slug</th>
                  <th class="pt-0 pb-2">Type</th>
                  <th class="pt-0 pb-2">Offset Posts</th>
                  <th class="pt-0 pb-2">Total Posts</th>
                  <th class="pt-0 pb-2">Is Public</th>
                  <th class="pt-0 pb-2">Template</th>
                  <!-- <th class="pt-0 pb-2">Action</th> -->
                </tr>
              </thead>
     
                <draggable v-if="homePageData[tab].length > 0"  v-model="homePageData[tab]" tag="tbody" @change="updateData">
                    <tr v-for="item in homePageData[tab]" :key="item.id">
             
                      <td class="align-middle">
                        {{ item.order_index+1 }}
                      </td>

                      <td class="align-middle">
                        <b>{{ item.category }}</b>
                      </td>
                      <td class="align-middle">
                        {{ item.slug }}
                      </td>
                      <td class="align-middle">
                        <b-form-select
                        v-model="item.type" class="form-select"
                          :options="types"
                          @change="update(item,'type')"
                        ></b-form-select>
                        
                      </td>
                      <td class="align-middle">
                        <input
                          v-model="item.offset_posts"
                          type="number"
                          @input="update(item, 'offset_posts')"
                        />
                      </td>
                      <td class="align-middle">
                        <input
                          v-model="item.total_posts"
                          type="number"
                          @input="update(item, 'total_posts')"
                        />
                      </td>
                      <td>
                        <div class="custom-control custom-switch">
                          <input
                            id="customSwitch1"
                            v-model="item.is_public"
                            type="checkbox"
                            class="custom-control-input"
                            @change="update(item, 'is_public')"
                          />
                          <label
                            class="custom-control-label"
                            for="customSwitch1"
                          ></label>
                        </div>
                      </td>
                       <td class="align-middle">
                        <b-form-select
                        v-model="item.template"
                          class="form-select"
                          :options="templates"
                          @change="update(item, 'template')"
                        ></b-form-select>
                        </td>
                        <!-- <td>
                        <a href="" @click.prevent="editItem(item)">
                          <i class="fas fa-pencil-alt fa-fw"></i>
                        </a>
                        </td> -->
                    </tr>
                </draggable>
    
            </table>
          </div>
          <!-- END table -->
        </div>
      </div>
    </div>

    <b-modal id="editModal" hide-footer title="Edit Homepage">
      <form>
        <div class="mb-3">
          <label for="category" class="form-label">Category Name</label>
          <b-form-select
                        v-model="id" class="form-select"
                          :options="categories"
                        ></b-form-select>
        </div>
        
        <button
          type="submit"
          class="btn btn-success float-end"
          @click.prevent="updateCategory"
        >
          Submit
        </button>
      </form>
    </b-modal>

  </div>
</template>

<script>
import { HTTP } from "../common/http"
import draggable from "vuedraggable"

export default {
  name: "HomePageData",
  components: {
    draggable,
  },
  data() {
    return {
      currentTab:0,
      id: 0,
      homePageData: [],
      categories: [],
      slug:'',
      errors: [],
      types: ['blog','tab', 'slide'],
      templates: ['blog_design_1','blog_design_2','tab_design_1', 'video_design_1'],
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  methods: {
    updateCategory(){
      HTTP.post(`admin/homepage/`, {id:this.id})
        .then(() => {
          this.homePageData=this.getData()
          
          this.$notify({
            group: "bottom-left",
            title: "success",
            type: 'success'
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    editItem(){
      this.getCategories()
      this.$bvModal.show('editModal')
    },
    getCategories(){
      HTTP.get("/admin/non-reserved-categories")
          .then(response => {
            this.categories = response.data.data
          })
          .catch(e => {
            this.errors.push(e)
          })
    },
    updateData(){
      let data = this.homePageData[this.types[this.currentTab]].map(item => item.id)
      HTTP.post(`admin/homepage-category-order`, {ordering:data})
        .then(() => {
            this.$notify({
            group: "bottom-left",
            title: "success",
            type: 'success'
          })
        })
        .catch(err => {
          console.log(err)
        })
      },
    update(item, attribute) {
      let data = { [attribute]: item[attribute] }
      HTTP.put(`admin/homepage/${item.id}`, data)
        .then(()=>{
          this.$notify({
            group: "bottom-left",
            title: "success",
            type: 'success'
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    getData() {
      HTTP.get("/admin/homepage")
        .then(response => {
          this.homePageData = response.data.data
          this.types = Object.keys(this.homePageData)
        })
        .catch(e => {
          this.errors.push(e)
        })
    },
  },
}
</script>
<style scoped>
tr :hover{
  cursor: move;
}
#customSwitch1 :hover{
 cursor: pointer !important;
}
</style>
