<script>
const sidebarMenu = [
  { path: '/dashboard', icon: 'fa fa-sitemap', title: 'Dashboard',
    children: [
      { path: '/dashboard/v1', title: 'Dashboard v1' }
    ]
  },
  { path: '/admin/posts', title: 'Posts', icon: 'fa fa-book'},
  { path: '/admin/category', title: 'Category', icon: 'fa fa-cubes' },
  { path: '/admin/authors', title: 'Authors', icon: 'fa fa-user' },
  { path: '/admin/videos', title: 'Videos', icon: 'fa fa-video-camera' },
  { path: '/admin/users', title: 'Users', icon: 'fa fa-users' },
  { path: '/admin/ads', title: 'Ads Management', icon: 'fa fa-bullhorn' },
  { path: '/admin/menu-builder', title: 'Menu Builder', icon: 'fa fa-bullhorn' },
  { path: '/admin/homepage-management', title: 'Homepage Management', icon: 'fa fa-building' },
   { path: '/admin/settings', title: 'Settings', icon: 'fa fa-cogs' },
  { path: '/admin/calendar', icon: 'fa fa-calendar', title: 'Calendar' },
  { path: '/admin/map', icon: 'fa fa-map', title: 'Map' },
  // { path: '/admin/gallery', icon: 'fa fa-image', title: 'Gallery' },

  // { path: '/admin/helper', icon: 'fa fa-medkit', title: 'Helper',
  //   children: [
  //     { path: '/helper/css', title: 'Predefined CSS Classes' }
  //   ]
  // },
]

export default sidebarMenu
</script>
