import Vue from 'vue'
import Router from 'vue-router'

import DashboardV1 from './pages/Dashboard-v1.vue'
import Calendar from './pages/Calendar.vue'
import Map from './pages/Map.vue'

import UserLoginV3 from './pages/User-login-v3.vue'
import Posts from './pages/Posts.vue'
import CreatePost from './pages/CreatePost.vue'
import auth from './middleware/auth'
import CrawlPosts from './pages/CrawlPosts.vue'
import Category from './pages/Category.vue'
import Users from './pages/Users.vue'
import AdsGallery from './pages/AdsGallery.vue'
import Settings from './pages/Settings.vue'
import Admin from './Admin.vue'
import Authors from './pages/Authors.vue'
import Videos from './pages/Videos.vue'
import CreateUsers from './pages/CreateUsers.vue'
import HomePageManagement from './pages/HomePageManagement.vue'
import MenuBuilder from './pages/MenuBuilder.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: { middleware: [auth]},
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: DashboardV1,
          meta: { middleware: [auth]}
        },
        {
          path: 'posts',
          name: 'posts',
          component: Posts,
          meta: { middleware: [auth]}
        },
        {
          path: 'crawler/:slug',
          component: CrawlPosts,
          meta: { middleware: [auth]}
        },       
        {
          path: 'posts/:id',
          component: CreatePost,
          meta: { middleware: [auth]}
        },
        {
          path: 'category',
          component: Category,
          meta: { middleware: [auth]}
        },
        {
          path: 'authors',
          component: Authors,
          meta: { middleware: [auth]}
        },
        {
          path: 'videos',
          component: Videos,
          meta: { middleware: [auth]}
        },
        {
          path: 'ads',
          component: AdsGallery,
          meta: { middleware: [auth]}
        },
        {
          path: 'settings',
          component: Settings,
          meta: { middleware: [auth]}
        },
        {
          path: 'users',
          component: Users,
          meta: { middleware: [auth]}
        },
        {
          name: 'create-users',
          path: 'users/create',
          component: CreateUsers,
          meta: { middleware: [auth]}
        },
        {
          name: 'menu-builder',
          path: 'menu-builder',
          component: MenuBuilder,
          meta: { middleware: [auth]}
        },
        {
          name: 'homepage-management',
          path: 'homepage-management',
          component: HomePageManagement,
          meta: { middleware: [auth]}
        },
        {
          path: 'calendar',
          component: Calendar,
          meta: { middleware: [auth]}
        },
        {
          path: 'map',
          component: Map,
          meta: { middleware: [auth]}
        },
   
      ]
    },
  
    { path: '/login', component: UserLoginV3 },
    { path:'*', redirect:'/admin'},
  ],
  
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router