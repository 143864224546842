<template>
  <div>
    <div class="card-body">
      <h4>List of Ads</h4>

      <b-list-group v-if="ads.data && ads.data.length > 0" id="ads-table">
         <draggable v-model="ads.data" tag="tbody" @change="updateData">
        <b-list-group-item
          v-for="ad in ads.data"
          :key="ad.id"
          class="d-flex justify-content-between align-items-baseline"
        >
          <p class="mb-0 ad">
             <span v-if="ad.offline == 0" class="indicator online float-start me-2"></span>
             <span v-else class="indicator offline float-start me-2"></span>
            {{ ad.order_index }} - {{ ad.title }}
          </p>
          <span>{{ad.place}}</span>

          <div class="actions d-flex">
            <i
              v-b-tooltip.hover.bottom
              title="Edit"
              class="fa fa-edit text-primary cursor-pointer mx-2 p-1" 
              @click="() => $emit('update', ad)"
            />
            <i
              v-b-modal.adDeleteModal
              v-b-tooltip.hover.bottom
              title="Delete"
              class="fa fa-trash cursor-pointer text-danger p-1" 
              @click="() => selectedAd = ad"
            />
          </div>
        </b-list-group-item>
         </draggable>
      </b-list-group>

      <b-pagination
        v-model="ads.current_page"
        class="mt-3"
        :total-rows="ads.total"
        :per-page="ads.per_page"
        aria-controls="ads-table"
        @change="(data) => $emit('getAds', data)"
      />
    </div>

    <!-- Delete Modal -->
		<b-modal
      id="adDeleteModal"
      title="Delete Ad?"
      ok-title="Delete"
      ok-variant="danger"
      @ok="$emit('deleteAd', selectedAd.id)"
    >
      Are you sure you want to delete this ad?
    </b-modal>
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BModal, BPagination, VBTooltip } from 'bootstrap-vue'

import draggable from "vuedraggable"
import { HTTP } from "../../common/http"

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BModal,
    BPagination,
    draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    ads: {
      type: Object,
      default: () => ({
        current_page:1
      })
    },
  },
  data() {
    return {
      selectedAd: null
    }
  },
  methods:{
    updateData(){
      let data = this.ads.data.map(item => item.id)
      HTTP.post(`admin/update-ads-order`, {ordering:data})
        .then(() => {
            this.$notify({
              group: "bottom-left",
              title: "success",
            })
        })
        .catch(err => {
          console.log(err)
        })
      },
  }
}
</script>

<style scoped>
::v-deep .close {
  border: none !important;
  background-color: transparent;
  font-size: 18px;
}


.indicator.online {
    background: #28B62C;
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    -webkit-animation: pulse-animation 2s infinite linear;
}

@-webkit-keyframes pulse-animation {
	0% { -webkit-transform: scale(1); }
	25% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(1.2) }
    75% { -webkit-transform: scale(1); }
    100% { -webkit-transform: scale(1); }
}

.indicator.offline {
    background: #848484;
    display: inline-block;
    width: 1em;
    height: 1em;
    
}
.list-group-item  :hover{
cursor:move;
}

</style>