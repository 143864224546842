<template>
  <div class="row">
    <div class="input-group mb-3">
      <!-- <button
        class="btn btn-white dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
      >
        <span class="d-none d-md-inline">Filter orders</span>
        <span class="d-inline d-md-none">
          <i class="fa fa-credit-card" />
        </span>
        <b class="caret" />
      </button> -->

      <!-- <div class="dropdown-menu">
        <a class="dropdown-item" href="#">Action</a>
        <a class="dropdown-item" href="#">Another action</a>
        <a class="dropdown-item" href="#">Something else here</a>
        <div role="separator" class="dropdown-divider" />
        <a class="dropdown-item" href="#">Separated link</a>
      </div> -->
      <div class="flex-fill position-relative">
        <div class="input-group">
          <div
            class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
            style="z-index: 1"
          >
            <i class="fa fa-search opacity-5" />
          </div>
          <input
            type="text"
            class="form-control px-35px bg-light"
            placeholder="Search orders..."
            @change="search"
          />
        </div>
      </div>
      <input
        ref="file"
        type="file"
        style="display: none"
        @change="uploadImage"
      />
      <a
        href="javascript:"
        class="btn btn-success btn-sm"
        @click="$refs.file.click()"
      >
        <i class="fa fa-fw fa-paperclip" />
        <span class="hidden-xs">Upload New</span>
      </a>
    </div>
    <div
      class="col-10"
    >
    <b-overlay :show="isProcessing">
      <div id="gallery" class="d-flex flex-wrap">
        <div v-for="(img, i) of images" :key="i" class="col-3 border bg-light">
          <div class="image" :class="childSelectedImage.includes(img)?'border-red':''">
            <label :for="i" class="image-inner m-2" role="button">
              <img :src="getImagePath(img)" class="w-100 h-100 photo" />
            </label>
            <div class="image-info">
              <!-- <p class="title">
                {{ img }}
              </p> -->
              <div class="d-flex align-items-center mb-2">
                <input
                  :id="i"
                  v-model="childSelectedImage"
                  :value="img"
                  name="image"
                  type="checkbox"
                  class="form-check-input rounded"
                />
                <i
                  class="fa fa-fw fa-trash text-danger ml-2 trash"
                  role="button"
                  @click="deleteFile(img)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
    </div>
    <div class="col-2 border-1">
      <div v-if="uploadedImage" class="d-flex border border-success">
        <a class="w-200px" href="javascript:;">
          <img :src="uploadedImage" alt="" class="mw-100 rounded" />
        </a>
      </div>

      <input
        ref="file"
        type="file"
        style="display: none"
        @change="uploadImage"
      />

      <div v-if="selectedImages.length > 1" class="d-flex border border-success">
        <div v-for="image,i in selectedImages.slice(0, selectedImages.length-1)" :key="i" class="w-200px border-red">
          <img :src="getImagePath(image)" alt="" class="w-100 rounded" />
        </div>
  
  <span class="position-absolute translate-middle badge rounded-pill bg-danger fs-5">
    {{selectedImages.length}}
  </span>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "./../common/http"

export default {
  name: "FileManager",
  props:{
    selectedImages: {
      type: Array,
      default: () =>[]
    }
  },
  data() {
    return {
      isProcessing: false,
      searchText: "",
      images: [],
      uploadedImage: null,
    }
  },
  computed:{
    childSelectedImage:{
      get(){
        return this.selectedImages
      }, set(newValue) {
       this.uploadedImage = this.getImagePath(newValue[newValue.length-1])
        this.$emit("uploadedImage", newValue)
      }
    }
  },
  created() {
    this.fetchImages()
  },
  methods: {
    fetchImages() {
      this.isProcessing = true,
      HTTP.get(`/admin/images`).then(res => {
        this.images = res.data.data
      }).finally(()=>{
        this.isProcessing = false
      })
    },
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.uploadedImage = URL.createObjectURL(files[0])
      // this.images = [this.uploadedImage]

      //upload image
      var formData = new FormData()
      const header = { "Content-Type": "multipart/form-data" }
      formData.append("file", files[0])
      HTTP.post("admin/image/upload", formData, header).then(res => {
        this.uploadedImage = this.getImage(res.data.data)

        const imagePath = 'uploads/'+res.data.data
        this.images.push(imagePath)
        this.$emit("uploadedImage", this.uploadedImage)
        this.$notify({
          group: "bottom-left",
          title: res.data.message,
        })
      })
    },
    search(e) {
      const images = this.images.filter(
        item => item.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
      )
      // images.sort((a,b) => a.localCompare(b)) //asc
      // images.sort((a,b) => b.localCompare(a)) //desc
      console.log(images)
    },
    // updateSelectedImages() {
    //   this.uploadedImage = this.getImagePath(this.selectedImages[0])
    //   this.$emit("uploadedImage", this.selectedImages)
    // },
    filterImage(image) {
      if (this.isBlob(image)) return image
      return this.getImage(image, null)
    },
    deleteFile(filename) {
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this imaginary file!",
        icon: "info",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          HTTP.post(`/admin/image/delete`, {
            filename: filename,
            _method: "DELETE",
          })
            .then(res => {
              this.$notify({
                group: "bottom-left",
                title: "success",
              })
              if (res.data.data) {
                const index = this.images.indexOf(filename)
        
                this.$delete(this.images, index)
              }
            })
            .catch(err => {
              console.log(err)
              this.$notify({
                group: "bottom-left",
                title: "failed",
              })
            })
        } else if (result.isDenied) {
          this.$notify({
            group: "bottom-left",
            title: "safe",
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.photo {
  height: 200px !important;
  overflow: hidden;
  object-fit: cover;
}
.image-info {
  margin-top: -20px;
}
.trash {
  font-size: 18px;
  margin-left: 200px;
}
#gallery {
  height: 500px;
  overflow: scroll;
}
.border-red{
  border: 1px solid red !important;
}
</style>
