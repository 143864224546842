<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item">
        <a href="javascript:;">Home</a>
      </li>
      <li class="breadcrumb-item">
        <a href="javascript:;">Settings</a>
      </li>
      <li class="breadcrumb-item active">Website General Settings</li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">
      Settings <small>update your all settings here</small>
    </h1>
    <!-- END page-header -->

    <h3 class="mb-3">Company Settings</h3>
    <!-- BEGIN row -->
    <div class="row mb-3">
      <!-- BEGIN col-6 -->
      <div class="col-xl-6">
        <!-- BEGIN panel -->
        <panel title="Company Information">
          <form>
            <fieldset>
              <div v-for="(info, i) of companyInfo" :key="i" class="mb-3">
                <label class="form-label" for="exampleInputEmail1">{{
                  info.key
                }}</label>
                <input
                  id="company_name"
                  v-model="info.value"
                  class="form-control"
                  type="text"
                  :placeholder="`${info.key}`"
                  @change="saveMeta(info, 'company_info')"
                />
              </div>
            </fieldset>
          </form>
        </panel>
        <!-- END panel -->
      </div>
      <!-- END col-6 -->
      <!-- BEGIN col-6 -->
      <div class="col-xl-6">
        <!-- BEGIN panel -->
        <panel title="Seo Settings">
          <div>
            <form>
              <fieldset v-if="seoSettings">
                <div v-for="(info, i) of seoSettings" :key="i" class="mb-3">
                  <label class="form-label" for="exampleInputEmail1">{{
                    info.key
                  }}</label>
                  <input
                    id="company_name"
                    v-model="info.value"
                    class="form-control"
                    type="text"
                    :placeholder="`${info.key}`"
                    @change="saveMeta(info, 'seo_setting')"
                  />
                </div>
                <div class="mb-3">
                  <div class="row">
                    <div class="col-6 d-flex">
                      <vue-dropzone
                        id="customdropzone"
                        ref="websiteLogo"
                        :use-custom-slot="true"
                        :options="dropzoneOptions"
                        @vdropzone-sending="sendingLogo"
                      >
                        <div class="dropzone-custom-content">
                          <h3 class="dropzone-custom-title">
                            Drag and drop to upload content!
                          </h3>
                          <div class="subtitle">Website Logo</div>
                        </div>
                      </vue-dropzone>
                    </div>
                    <div class="col-6 border preview">
                      <img :src="siteLogo" width="200px" />
                      <span>
                        <p class="form-label" for="description">Website Logo</p>
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </panel>
      </div>
      <!-- END col-6 -->
    </div>

    <div class="row mb-3">
      <!-- BEGIN col-6 -->
      <div class="col-xl-6">
        <!-- BEGIN panel -->
        <panel title="External Links">
          <form>
            <fieldset>
              <div
                v-for="(data, i) of externalLinks"
                :key="i"
                class="mb-3 d-flex"
              >
                <label class="form-label me-2">{{ data.key }}</label>

                <input
                  v-model="data.value"
                  class="form-control"
                  type="text"
                  :placeholder="`Link URL ${i}`"
                />

                <a
                  v-if="i < externalLinks.length"
                  href=""
                  class="btn btn-default btn-icon btn-lg float-end ms-2"
                  @click.prevent="deleteLink(data)"
                >
                  <i class="fa fa-pencil text-warning" />
                </a>
                <a
                  v-if="i == externalLinks.length - 1"
                  href=""
                  class="btn btn-default btn-icon btn-lg float-end ms-2"
                  @click.prevent="addMoreLinks"
                >
                  <i class="fa fa-plus text-success" />
                </a>
              </div>
            </fieldset>
          </form>
        </panel>
        <!-- END panel -->
      </div>
      <!-- END col-6 -->
      <!-- BEGIN col-6 -->
      <div class="col-xl-6">
        <panel title="Advertisement Setting">
          <form>
            <fieldset>
              <div v-for="(info, i) of homePageAdPlaces" :key="i" class="mb-3">
                <label class="form-label" for="exampleInputEmail1">{{
                  info.key
                }}</label>
                <input
                  v-model="info.value"
                  class="form-control"
                  type="text"
                  :placeholder="`${info.key}`"
                  @change="saveMeta(info, 'ad_places')"
                />
              </div>
            </fieldset>
          </form>
        </panel>
        <panel title="Homepage Color Setting">
          <form>
            <fieldset>
              <div
                v-for="(col, i) of homePageColorSettings"
                :key="i"
                class="mb-3"
              >
                <label class="form-label me-4" for="exampleInputEmail1">{{
                  col.key
                }}</label>
                <VueColorpicker
                  v-model="col.value"
                  @change="saveMeta(col, 'homepage_color')"
                ></VueColorpicker>
              </div>
            </fieldset>
          </form>
        </panel>
      </div>

      <!-- END mailbox-content -->
      <b-modal
        id="modalLink"
        cancel-variant="default"
        size="sm"
        :hide-footer="false"
      >
        <template slot="modal-header"> Add Link </template>
        <fieldset>
          <input
            v-model="linkName"
            class="form-control mb-2"
            type="text"
            placeholder="Link Name"
          />

          <input
            v-model="link"
            class="form-control"
            type="text"
            placeholder="Link URL"
          />
          <button
            type="submit"
            class="btn btn-success ps-40px pe-40px my-2 float-end"
            @click.stop="saveExternalLinks"
          >
            Save
          </button>
        </fieldset>
      </b-modal>
    </div>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import { VueColorpicker } from "vue-pop-colorpicker"
import { HTTP } from "./../common/http"

export default {
  name: "Settings",
  components: {
    vueDropzone,
    VueColorpicker,
  },
  data() {
    return {
      color: "",
      link: "",
      linkName: "",
      siteLogo: "",
      seoSettings: [],
      externalLinks: [
        { 
          key: "facebook", 
          value: "https://facebook.com/",
          type: 'external_links'
          },
          { 
          key: "youtube", 
          value: "https://youtube.com/",
          type: 'external_links'
          },
          { 
          key: "twitter", 
          value: "https://twitter.com/",
          type: 'external_links'
          },
        ],
      companyInfo: [
        { 
          key: "company_name", 
          value: "company name",
          type: 'company_info'
          },
          { 
          key: "slogan", 
          value: "company slogan",
          type: 'company_info'
          },
          { 
          key: "company_description", 
          value: "No one news portal",
          type: 'company_info'
          },
          { 
          key: "phone", 
          value: "company phone",
          type: 'company_info'
          },
          { 
          key: "address", 
          value: "company address",
          type: 'company_info'
          },
          { 
          key: "fax", 
          value: "company fax",
          type: 'company_info'
          },
          { 
          key: "geo_map", 
          value: '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14128.005405703296!2d85.32395955!3d27.71724455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1670820303678!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>',
          type: 'company_info'
          },
        { 
          key: "copyrite", 
          value: "@copyrite 2022. All rights reserved",
          type: 'company_info'
          },
        ],
      site_favicon: "",
      homePageAdPlaces: [
        { 
          key: "ad_places", 
          value: "[2,2,3,4]",
          type: 'ad_places'
          },
      ],
      homePageColorSettings: [
        {
          key: "backgroundColorTopMenu",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "backgroundColorMainMenu",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "backgroundColorHeader",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "backgroundColorFooter",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "backgroundColorMidCategoryBadge",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "titleColor",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "titleHoverColor",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_1_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_2_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_3_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_4_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_5_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_6_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_7_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_8_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
        {
          key: "section_9_bg_color",
          type: "homepage_color",
          value: "#ddd",
        },
      ],
      dropzoneOptions: {
        addRemoveLinks: true,
        url: `${process.env.VUE_APP_API_BASE_URL + "/admin/image/upload"}`,
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { Authorization: "Bearer " + this.getToken() },
      },
    }
  },
  created() {
    this.getMeta()
  },
  methods: {
    sendingLogo(file, xhr, formData) {
      formData.append("type", "site_logo")
    },
    addMoreLinks() {
      this.$bvModal.show("modalLink")
    },
    deleteLink(data) {
      //TODO: delete method
      console.log(data)
      this.$bvModal.show("modalLink")
      this.linkName = data.key
      this.link = data.value
    },
    saveExternalLinks() {
      const info = {
        key: this.linkName,
        value: this.link,
        is_public: 1,
      }
      this.saveMeta(info, "external_links")
      this.externalLinks.push(info)
      this.$bvModal.hide("modalLink")
    },
    saveMeta(info, type) {
      const data = {
        key: info.key,
        value: info.value,
        type: type,
        is_public: 1,
      }

        this.storeMeta(data)
      
    },
    storeMeta(data) {
      HTTP.post("admin/meta", data)
        .then(() => {
          this.$notify({
            group: "bottom-left",
            title: "success",
            type: "success",
          })
        })
        .catch(err => {
          console.log(err)
          this.$notify({
            group: "bottom-left",
            title: "failed",
            type: "error",
          })
        })
    },
    getMeta() {
      HTTP.get("admin/meta/website_settings")
        .then(res => {
          const data = res.data.data

          var ids = new Set(data.homepage_color.map(d => d.key));
          this.homePageColorSettings = [...data.homepage_color,...this.homePageColorSettings.filter(d => !ids.has(d.key))];

          var arr = new Set(data.company_info.map(d => d.key));
          this.companyInfo = [...data.company_info,...this.companyInfo.filter(d => !arr.has(d.key))];

          // this.companyInfo = this.arrayMerge(this.companyInfo, data.company_info)
          var arrExternalLinks = new Set(data.external_links.map(d => d.key));
          this.externalLinks = [...data.external_links,...this.externalLinks.filter(d => !arrExternalLinks.has(d.key))];

          // this.externalLinks = data.external_links
          this.homePageAdPlaces = data.ad_places
          this.siteLogo = this.getImage(data.site_logo[0].value)

          var file = { size: 123, name: "Icon", type: "image/png" }
          var url = this.getImage(data.site_logo)
          this.$refs.websiteLogo.manuallyAddFile(file, url)
          // this.company_name = res.data.
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped>
#customdropzone {
  background-color: rgb(255, 242, 219) !important;
  color: #777;
  transition: background-color 0.2s linear;
}
.preview {
  height: 260px;
  overflow: hidden;
  width: auto;
  object-fit: cover;
}
</style>
