import { render, staticRenderFns } from "./MenuBuilder.vue?vue&type=template&id=a35c2528&scoped=true&"
import script from "./MenuBuilder.vue?vue&type=script&lang=js&"
export * from "./MenuBuilder.vue?vue&type=script&lang=js&"
import style0 from "./MenuBuilder.vue?vue&type=style&index=0&id=a35c2528&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a35c2528",
  null
  
)

export default component.exports