<template>
  <div>
    <b-overlay :show="isLoading">
      <template #overlay>
        <b-spinner
          v-for="variant in variants"
          :key="variant"
          :variant="variant"
          type="grow"
        />
      </template>
      <div class="d-flex align-items-center mb-3">
        <div>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="javascript:;">Home</a>
            </li>
            <li class="breadcrumb-item active">Posts</li>
          </ol>
          <h1 class="page-header mb-0">Posts</h1>
        </div>
        <div class="ms-auto">
          <!-- <a @click="$bvModal.show('modalDialog')" class="btn btn-success btn-rounded px-4 rounded-pill"><i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900"></i> Add Product</a> -->
          <a
            class="btn btn-success btn-rounded px-4 rounded-pill"
            @click.prevent="$router.push('/admin/posts/create')"
          >
            <i class="fa fa-plus fa-lg me-2 ms-n2 text-success-900" /> Add Post
          </a>
        </div>
      </div>

      <!-- <div class="mb-3 d-sm-flex fw-bold">
        <div class="mt-sm-0 mt-2">
          <a href="javascript:;" class="text-dark text-decoration-none"
            ><i class="fa fa-download fa-fw me-1 text-dark text-opacity-50" />
            Export</a
          >
        </div>
        <div class="ms-sm-4 ps-sm-1 mt-sm-0 mt-2">
          <a href="javascript:;" class="text-dark text-decoration-none"
            ><i class="fa fa-upload fa-fw me-1 text-dark text-opacity-50" />
            Import</a
          >
        </div>
        <b-dropdown
          class="ms-sm-4 ps-sm-1 mt-sm-0 mt-2 dropdown-toggle"
          variant="link"
          toggle-class="text-dark text-decoration-none border-0 p-0 fw-bold"
        >
          <template slot="button-content">
            <i class="fa fa-cog fa-fw me-1 text-dark text-opacity-50" /> More
            Actions <b class="caret" />
          </template>
          <a class="dropdown-item" href="javascript:;">By Category</a>
          <a class="dropdown-item" href="javascript:;">By ID</a>
          <a class="dropdown-item" href="javascript:;">By Created Date</a>
          <div role="separator" class="dropdown-divider" />
          <a class="dropdown-item" href="javascript:;">By Title</a>
        </b-dropdown>
      </div> -->

      <div class="card border-0">
        <ul class="nav nav-tabs nav-tabs-v2 px-3">
          <li class="nav-item me-2">
            <a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab">All</a>
          </li>
          <li class="nav-item me-2">
            <a href="#publishedTab" class="nav-link px-2" data-bs-toggle="tab">Published</a>
          </li>
          <li class="nav-item me-2">
            <a href="#draftedTab" class="nav-link px-2" data-bs-toggle="tab">Drafted</a>
          </li>
          <li class="nav-item me-2">
            <a href="#deletedTab" class="nav-link px-2" data-bs-toggle="tab">Trashed</a>
          </li>
        </ul>
        <div class="tab-content p-3">
          <div id="allTab" class="tab-pane fade show" :class="{active: activeTab === 'allTab'}" @click.stop="activateTab('allTab')">
            <!-- BEGIN input-group -->
            <div class="input-group mb-3">
              <b-dropdown
                variant="white"
                toggle-class="rounded-0 rounded-start"
              >
                <template slot="button-content">
                  Filter Posts <b class="caret" />
                </template>
                <a class="dropdown-item" href="javascript:;" @click="filterPosts('category_id')">By Category</a>
                <a class="dropdown-item" href="javascript:;" @click="filterPosts('id')">By ID</a>
                <a class="dropdown-item" href="javascript:;" @click="filterPosts('created_at')">By Created Date</a>
                <div role="separator" class="dropdown-divider" />
                <a class="dropdown-item" href="javascript:;" @click="filterPosts('title')">By Title</a>
                  
              </b-dropdown>
              <div class="flex-fill position-relative">
                <div class="input-group">
                  <div
                    class="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                    style="z-index: 1"
                  >
                    <i class="fa fa-search opacity-5" />
                  </div>
                  <input
                    type="text"
                    class="form-control ps-35px bg-light"
                    placeholder="Search posts..."
                    @change="searchPosts"
                  />
                </div>
              </div>
            </div>
            <!-- END input-group -->

            <!-- BEGIN table -->
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th class="pt-0 pb-2" />
                    <th class="pt-0 pb-2">Image</th>
                    <th class="pt-0 pb-2">Title</th>
                    <th class="pt-0 pb-2">Category</th>
                    <th class="pt-0 pb-2">Date</th>
                    <th class="pt-0 pb-2">Views</th>
                    <th class="pt-0 pb-2">visibility</th>
                    <th class="pt-0 pb-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(post, index) in posts.data" :key="post.id">
                    <td class="w-10px align-middle">
                      <div class="form-check">
                        <input
                          id="product1"
                          type="checkbox"
                          class="form-check-input"
                        />
                        <label class="form-check-label" for="product1" />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div
                          class="w-50px h-50px bg-light d-flex align-items-center justify-content-center"
                        >
                          <img
                            alt=""
                            class="mw-100 mh-100"
                            :src="getImage(post.filename)"
                          />
                        </div>
                      </div>
                    </td>
                    <td class="align-middle">
                      <b>{{ post.title.slice(0,50) }}<span v-if="post.title.length > 50">...</span></b>
                    </td>
                    <td class="align-middle">
                      <span v-for="(category, i) in post.category" :key="category.id">
                        <span
                          v-if="(i < 3)" 
                          class="badge bg-primary ms-1"
                        >
                          {{ category.name }}
                        </span>
                      </span>
                      <span 
                        v-if="(post.category.length > 3)"
                        v-b-modal.category-list
                        class="badge badge-pill bg-light text-secondary ms-1"
                        @click="selectCategoryList(post.id)"
                      >
                        +{{post.category.length - 3}}
                      </span>
                    </td>
                    <td class="align-middle">
                      {{ post.np_date }}
                    </td>
                    <td class="align-middle">
                      {{ post.views }}
                    </td>
                    <td class="align-middle">
                      <b-form-checkbox
                      v-model="post.is_public"
                      switch
                      :value="1"
                      type="checkbox"
                      class="custom-control-input"
                      @change="update(post, 'is_public')"
                    />
                    <label
                      class="custom-control-label"
                      for="customSwitch1"
                    ></label>
                    </td>
                    <td>
                      <div class="fa-1x">
                        <a
                          href=""
                          @click.prevent="
                            $router.push(`/admin/posts/${post.id}`)
                          "
                        >
                          <i class="fas fa-pencil-alt fa-fw" />
                        </a>
                        <a
                          href=""
                          class="text-danger ps-2"
                          @click.prevent="deletePost(post.id, index)"
                        >
                          <i class="fas fa-trash fa-fw text-danger" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- END table -->

            <!-- pagination -->
            <div class="float-end">
              <b-pagination
                :total-rows="posts.total"
                :value="1"
                :per-page="posts.perPage"
                @input="getData"
              />
            </div>
          </div>
          <div id="draftedTab" class="tab-pane fade show" :class="{active: activeTab === 'draftedTab'}" @click="activateTab('draftedTab')">Drafted</div>
          <div id="deletedTab" class="tab-pane fade show" :class="{active: activeTab === 'deletedTab'}" @click="activateTab('deletedTab')">Trashed</div>
        </div>
       
      </div>
      <!-- 
		<b-modal id="modalDialog" cancel-variant="default" size="xl">
			<template slot="modal-header">
				<h4 class="modal-title">Create Post</h4>
				<a class="btn-close" @click="$bvModal.hide('modalDialog')"></a>
			</template>
			<create-post />
		</b-modal> -->
    </b-overlay>

    <!-- Category List Modal -->
    <b-modal
      id="category-list"
      title="Category List"
      ok-only
      ok-title="Close"
    >
      <span
        v-for="category in currentCategoryList"
        :key="category.id"
        class="badge bg-primary me-2"
      >
        {{ category.name }}
      </span>
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from "./../common/http"
// import CreatePost from './CreatePost'

export default {
  components: {
    // CreatePost,
  },
  data() {
    return {
      filterOption: 'id',
      activeTab: 'allTab',
      variants: [
        "primary",
        "secondary",
        "danger",
        "warning",
        "success",
        "info",
        "light",
        "dark",
      ],
      isLoading: false,
      currentPage: 1,
      posts: [],
      errors: [],
      currentCategoryList: [],
    }
  },

  created() {
    this.getData()
  },
  methods: {
    update(post, attribute){
      HTTP.put(`/admin/posts/${post.id}`, {[attribute]:post[attribute]}).then(res=>{
        console.log(res.data.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    getData(page = 1) {
      this.isLoading = true
      HTTP.get(`posts?page=${page}&orderBy=${this.filterOption}`)
        .then(response => {
          this.posts = response.data.data
        })
        .catch(e => {
          this.errors.push(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    deletePost(id, index) {
      let i = index
      this.$swal({
        title: "Are you sure?",
        text: "You will not be able to recover this imaginary file!",
        icon: "info",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Delete",
        denyButtonText: `Don't Delete`,
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          HTTP.delete(`posts/${id}`).then(() => {
            this.$delete(this.posts.data, i)
       
            this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Deleted Successfully',
              showConfirmButton: false,
              timer: 1500
            })
          })
        } else if (result.isDenied) {
          this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Your data has Saved',
              showConfirmButton: false,
              timer: 1500
            })
        }
      })
    },
    searchPosts(e) {
      if (e.target.value) {
        const posts = this.posts.data.filter(
          item =>
            item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        )

        this.posts.data = posts
      } else {
        this.getData()
      }
    },
    filterPosts(option){
      console.log(option)
      this.filterOption = option
      this.getData()
      
    },
    activateTab(tab){
      console.log(tab)
      this.activeTab = tab
    },
    selectCategoryList(id) {
      this.currentCategoryList = this.posts.data.find(post => post.id === id).category
    },
  },
}
</script>
